/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, SideModal, Typography } from 'design_system/src';
import { toasterOptions } from 'models/shared/enums/toaster.enum';
import { LightTooltip } from 'components/hocs/tooltip/Tooltip';

import { VehicleStatus } from 'models/inventory/enums/vehicle.enum';

import '../ozoner.scss';
import { ReactComponent as Helmet } from 'design_system/src/static/icons/helmet.svg';
import { ReactComponent as Back } from 'design_system/src/static/icons/back.svg';
import { ReactComponent as Eye } from 'design_system/src/static/icons/eye.svg';
import { ReactComponent as Refresh } from 'design_system/src/static/icons/refresh.svg';
import { ReactComponent as Copy } from 'design_system/src/static/icons/copy.svg';
import { ReactComponent as Monitor } from 'design_system/src/static/icons/monitor.svg';
import { ReactComponent as Panda } from 'design_system/src/static/icons/panda.svg';
import { ReactComponent as EyeOff } from 'design_system/src/static/icons/eye-off.svg';
import { ReactComponent as Reload } from 'design_system/src/static/icons/reload.svg';
import { ReactComponent as Whatsapp } from 'design_system/src/static/icons/whatsapp.svg';
import { ReactComponent as Mail } from 'design_system/src/static/icons/mail.svg';
import { ReactComponent as SMS } from 'design_system/src/static/icons/chat-square-plain.svg';
import { ReactComponent as Add } from 'design_system/src/static/icons/add-circle.svg';
import { ReactComponent as Down } from 'design_system/src/static/icons/chevron-down.svg';

import History from 'components/hocs/history/History';
import Inventory from 'views/inventory/Inventory';
import { handleEventGoogle } from 'models/shared/helpers/googleAnalytics';
import { LoanStatus, LoanStatusValues } from 'models/loan/enums/loan.enum';
import { FormStatusValues } from 'models/financial-form/enums/financial-form.enums';
import { OzonerStatusValues } from 'models/ozoner/enums/ozoner.enums';
import { Vehicle } from 'models/inventory/interfaces/vehicle.interface';
import { updateFinancialForm } from 'models/financial-form/controllers/financial-form.controller';
import { toasterNotification } from 'models/shared/helpers/toasterNotification';
import TruoraDetails from '../platforms/TruoraDetails';
import PalencaDetails from '../platforms/PalencaDetails';
import DataOzoner from './sections/DataOzoner';
import DocumentsOzoner from './sections/DocumentsOzoner';
import EditOzoner from '../edits/EditOzoner';
import HistoryStatus from './statusHistory/HistoryStatus';
import { ozonerContext } from './OzonerContext';
import { LoanOption } from './components/LoanOption';
import SolicitudOzoner from './sections/SolicitudOzoner/SolicitudOzoner';
import LoanOzoner from './sections/LoanOzoner';

export const displayQuota = (quota: number) => {
	if (quota) {
		const quotaToStr = quota.toString();
		return `0,${quotaToStr}`;
	}
	return `0,${0}`;
};

// eslint-disable-next-line no-shadow
export enum EditTypesOzoner {
	REGULAR = 0,
	BASIC_DATA = 1,
	PERSONAL_REFERENCES = 2,
	FINANCIAL_DATA = 3,
	NEW_SOLICITUD = 4,
	OZONER_DATA = 5,
}

const DetailsOzoner = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { _id } = useParams();

	// -----------------LOGIC STATES------------------- //
	const [openHistory, setOpenHistory] = useState<boolean>(false);
	const [openPalenca, setOpenPalenca] = useState(false);
	const [openTruora, setOpenTruora] = useState(false);
	const [openNotifications, setOpenNotifications] = useState(false);
	const [openDetails, setOpenDetails] = useState<boolean>(false);
	const [openLoans, setOpenLoans] = useState<boolean>(false);

	const [editData, setEditData] = useState<any>();

	const {
		initializeContext,
		setId,
		tab,
		setTab,
		ozoner,
		selfie,
		setFormId,
		loading,
		financialForm,
		financialForms,
		openVehicle,
		setOpenVehicle,
		hasLoan,
	} = useContext(ozonerContext);

	useEffect(() => {
		setOpenDetails(true);
	}, [editData]);

	useEffect(() => {
		setId(_id);
		const queryParams = new URLSearchParams(location.search);
		const tabParam = queryParams.get('tab');
		const edit = queryParams.get('openEdit');
		const formId = queryParams.get('formId');
		if (formId) setFormId(formId);

		if (tabParam) setTab(parseInt(tabParam, 10));
		if (edit === 'true') {
			openEditOzoner('Crear nueva solicitud', EditTypesOzoner.NEW_SOLICITUD);
		}
	}, [_id, location.search]);

	useEffect(() => {
		switch (tab) {
			case 0:
				handleEventGoogle(
					'TAB_Pb_request_info',
					'Panda Solicitudes',
					'tab view to the info ozoner in request',
				);
				break;
			case 1:
				handleEventGoogle(
					'TAB_Pb_request_documents',
					'Panda Solicitudes',
					'tab view to the documents ozoner in request ',
				);
				break;
			case 2:
				handleEventGoogle(
					'TAB_Pb_request_financial',
					'Panda Solicitudes',
					'tab view to the financial ozoner in request',
				);
				break;
			case 3:
				handleEventGoogle(
					'TAB_Pb_request_collection',
					'Panda Solicitudes',
					'tab view to the collection ozoner in request',
				);
				break;

			default:
				break;
		}
	}, [tab]);

	const openEditOzoner = (label: string, editType: number) => {
		switch (editType) {
			case EditTypesOzoner.BASIC_DATA:
				setEditData({ label, sections: [true, false, false, false] });
				break;
			case EditTypesOzoner.OZONER_DATA:
				setEditData({ label, sections: [true, true, false, false] });
				break;
			case EditTypesOzoner.FINANCIAL_DATA:
				setEditData({ label, sections: [false, false, false, true] });
				break;
			case EditTypesOzoner.PERSONAL_REFERENCES:
				setEditData({ label, sections: [false, false, true, false] });
				break;
			case EditTypesOzoner.NEW_SOLICITUD:
				setEditData({
					label,
					sections: [false, true, true, true],
					form: true,
				});
				break;
			default:
				setEditData({ label });
				break;
		}
	};

	const changeForm = (id: string) => {
		navigate(`/ozoner/${_id}?formId=${id}`);
		setOpenLoans(false);
	};
	const createNewForm = () => {
		openEditOzoner('Crear nueva solicitud', EditTypesOzoner.NEW_SOLICITUD);
		setOpenLoans(false);
	};

	const getPalencaLink = () => {
		navigator.clipboard
			.writeText(
				`${process.env.REACT_APP_PALENCA_BASE_URL}?widget_id=${process.env.REACT_APP_PALENCA_WIDGET}&is_sandbox=${process.env.REACT_APP_PALENCA_IS_SANDBOX}&external_id=${ozoner?._id}`,
			)
			.then(() => {
				toasterNotification({
					toasterType: toasterOptions.success,
					msg: 'Copiado al portapapeles',
					style: 'light',
				});
			})
			.catch((error) => {
				toasterNotification({
					toasterType: toasterOptions.error,
					msg: `Error al copiar el contenido: ${error}`,
					style: 'light',
				});
			});
	};

	return !loading && ozoner && financialForm ? (
		<>
			<div className="dim_100_per">
				<div className="shadow_hard br_xs p_x_md p_y_xs display_flex flex_gap_md flex_align_center m_b_lg">
					<Button
						variant="icon"
						icon={<Back />}
						subvariant="edit"
						scale="small"
						onClick={() => navigate(-1)}
						className="flex_align_self_start"
					/>
					<div
						id="preview-ozoner"
						className="display_flex flex_gap_md flex_align_center flex_grow_1"
					>
						<div
							className={`br_sm border_xxs border_solid photo_ozoner  display_flex overflow_hidden img_ozoner border_${
								OzonerStatusValues[ozoner.status]?.color
							}`}
						>
							<img src={selfie} alt="" className="dim_100_per img_cover" />
						</div>
						<div className="display_flex flex_justify_between flex_col flex_gap_xs ">
							<div className="display_flex flex_gap_md flex_align_center">
								<div className="p_y_xxxs p_x_xxs br_xxxs bg_neutral_300 flex_center flex_gap_xs">
									{ozoner.createdOn === 'pandaboard' ? (
										<Panda className=" dim_sm text_primary_200" />
									) : (
										<Monitor className=" dim_sm text_primary_200" />
									)}
									<Typography
										scale="small"
										textColor="neutral_800"
										weight="400"
										className="text_capitalize"
									>
										{ozoner.createdOn}
									</Typography>
								</div>
								{financialForm?.form?.financialInformation
									?.workInDigitalPlatforms ? (
									<div className="p_y_xxxs p_x_xxs br_xxxs bg_neutral_300 flex_center flex_gap_xs">
										<Helmet className=" dim_sm text_primary_200" />
										<Typography
											scale="small"
											textColor="neutral_800"
											weight="400"
											className="text_capitalize"
										>
											Trabaja en plataformas
										</Typography>
									</div>
								) : null}
							</div>
							<Typography
								scale="medium"
								textColor="neutral_900"
								weight="600"
								className="text_capitalize"
							>
								{ozoner.name} {ozoner.lastName}
							</Typography>
							{/* TODO:Implement Truora again */}
							<div className="display_flex flex_gap_xs flex_align_center">
								{/* <LightTooltip
								title={
									ozoner.truoraData
										? validTruora
											? 'Validación de identidad'
											: 'Falló en la validación de identidad'
										: 'No se ha realizado la validación de identidad'
								}
							>
								<div
									className={`br_xs overflow_hidden display_flex flex_align_center ${
										ozoner.truoraData
											? `bg_${
													validTruora ? 'secondary_600' : 'red_300'
											  }_nocontrast text_neutral_0`
											: 'bg_neutral_600_nocontrast text_neutral_800'
									}`}
								>
									<Typography
										scale="small"
										weight="600"
										className="p_y_xxxs p_x_sm flex_grow_1 text_center platforms_text"
									>
										Truora
									</Typography>
									{ozoner.truoraData && (
										<div
											className={`p_x_xs ${
												validTruora ? 'truora_trigger' : 'bg_red_400_nocontrast'
											} flex_center cursor_pointer flex_align_self_stretch`}
											role="button"
											tabIndex={0}
											onClick={() => {
												setOpenTruora(true);
												handleEventGoogle(
													'BTN_Pb_request_truora',
													'Panda Solicitudes',
													'click in truora button ',
												);
											}}
											onKeyPress={() => {
												setOpenTruora(true);
												handleEventGoogle(
													'BTN_Pb_request_truora',
													'Panda Solicitudes',
													'click in truora button ',
												);
											}}
										>
											<Eye className="dim_md text_neutral_0" />
										</div>
									)}
								</div>
							</LightTooltip> */}
								<div
									className={`br_xs overflow_hidden display_flex flex_align_center ${
										(ozoner as any).palencaData &&
										Object.keys((ozoner as any).palencaData).length
											? 'bg_green_300_nocontrast text_neutral_0'
											: 'bg_neutral_600_nocontrast text_neutral_800'
									} `}
								>
									<Typography
										scale="small"
										weight="600"
										className="p_y_xxs p_x_sm flex_grow_1 text_center platforms_text"
									>
										Palenca
									</Typography>
									{(ozoner as any).palencaData &&
									Object.keys((ozoner as any).palencaData).length ? (
										<div
											className="p_x_xs palenca_trigger flex_align_self_stretch flex_center cursor_pointer"
											role="button"
											tabIndex={0}
											onClick={() => {
												setOpenPalenca(true);
												handleEventGoogle(
													'BTN_Pb_request_palenca',
													'Panda Solicitudes',
													'click in palenca button ',
												);
											}}
											onKeyPress={() => {
												setOpenPalenca(true);
												handleEventGoogle(
													'BTN_Pb_request_palenca',
													'Panda Solicitudes',
													'click in palenca button ',
												);
											}}
										>
											<Eye className="dim_md text_neutral_0 " />
										</div>
									) : (
										<LightTooltip title="Copiar link de palenca">
											<div
												className="p_x_xs palenca_trigger flex_align_self_stretch flex_center cursor_pointer"
												role="button"
												tabIndex={0}
												onClick={() => {
													getPalencaLink();
													handleEventGoogle(
														'BTN_Pb_request_palenca',
														'Panda Solicitudes',
														'click in palenca button ',
													);
												}}
												onKeyPress={() => {
													getPalencaLink();
													handleEventGoogle(
														'BTN_Pb_request_palenca',
														'Panda Solicitudes',
														'click in palenca button ',
													);
												}}
											>
												<Copy className="dim_md text_neutral_0" />
											</div>
										</LightTooltip>
									)}
								</div>
							</div>
						</div>
					</div>
					<div
						id="messages"
						className="p_md br_xs bg_neutral_200 display_flex flex_align_center flex_gap_md"
					>
						<Button
							scale="small"
							variant="tint"
							icon={<Refresh />}
							onClick={() => {
								setOpenHistory(true);
								handleEventGoogle(
									'BTN_Pb_ozoner_historic_changes',
									'Panda Ozoners',
									'click in historic changes ozoner button',
								);
							}}
							className="shadow_none"
						>
							Historial de cambios
						</Button>
					</div>
					<div id="credit-selection" className="pos_relative">
						<div
							className="pos_relative cursor_pointer"
							onClick={() => setOpenLoans(!openLoans)}
							role="button"
							tabIndex={0}
							onKeyDown={() => setOpenLoans(!openLoans)}
						>
							{LoanOption(financialForm)}
							<Down
								className={`dim_lg open_credits ${openLoans ? 'open' : ''}`}
							/>
						</div>
						<div
							className={`credits_section shadow_hard bg_neutral_0 ${
								openLoans ? 'open' : ''
							}`}
						>
							{financialForms
								?.filter((form) => financialForm?.form?._id !== form?.form?._id)
								.map((displayForm) =>
									LoanOption(displayForm, () =>
										changeForm(displayForm?.form?._id),
									),
								)}
							<div
								className="p_xl flex_gap_xs border_solid border_1 border_neutral_400 flex_center cursor_pointer"
								role="button"
								tabIndex={0}
								onClick={createNewForm}
								onKeyDown={createNewForm}
							>
								<Typography
									weight="600"
									scale="medium"
									textColor="neutral_900"
									className=""
								>
									Nueva solicitud de crédito
								</Typography>
								<Add className="dim_lg" />
							</div>
						</div>
					</div>
				</div>
				<div className="display_flex">
					<Typography
						scale="medium"
						weight="600"
						onClick={() => {
							setTab(0);
						}}
						className={`cursor_pointer text_center p_x_md p_y_xs ozoner_tab ${
							tab === 0 ? 'active' : ''
						}`}
					>
						Datos
					</Typography>
					<Typography
						scale="medium"
						weight="600"
						onClick={() => {
							setTab(1);
						}}
						className={`cursor_pointer text_center p_x_md p_y_xs ozoner_tab ${
							tab === 1 ? 'active' : ''
						}`}
					>
						Documentos
					</Typography>
					<Typography
						scale="medium"
						weight="600"
						onClick={() => {
							setTab(2);
						}}
						className={`cursor_pointer text_center p_x_md p_y_xs ozoner_tab ${
							tab === 2 ? 'active' : ''
						}`}
					>
						Solicitud
					</Typography>
					{financialForm?.loan && (
						<Typography
							scale="medium"
							weight="600"
							onClick={() => {
								setTab(4);
							}}
							className={`cursor_pointer text_center p_x_md p_y_xs ozoner_tab ${
								tab === 4 ? 'active' : ''
							}`}
						>
							Crédito
						</Typography>
					)}
				</div>
				<div className="p_sm border_1 border_solid border_neutral_400 bg_neutral_100">
					{tab === 0 && <DataOzoner openEditOzoner={openEditOzoner} />}
					{tab === 1 && <DocumentsOzoner />}
					{tab === 2 && <SolicitudOzoner />}
					{tab === 4 && <LoanOzoner />}
				</div>
			</div>
			{openVehicle && (
				<SideModal open={openVehicle} setOpen={setOpenVehicle}>
					<div className="pos_relative h_100_per">
						<Inventory
							onClick={(v: Vehicle) => {
								updateFinancialForm(financialForm.form._id, {
									vehicle: v._id,
									loanApplication: {
										loanDuration: null,
									},
								})
									.then(() => {
										toasterNotification({
											msg: 'Solicitud Exitosa',
											style: 'light',
											toasterType: toasterOptions.success,
										});
										initializeContext();
									})
									.catch((e) => {
										toasterNotification({
											msg: e ?? 'Ocurrio un error',
											toasterType: toasterOptions.error,
											style: 'light',
										});
									});
								handleEventGoogle(
									'BTN_Pb_request_revive',
									'Panda Solicitudes',
									'click in revive request button',
								);
								setOpenVehicle(false);
							}}
							initialFilter={[VehicleStatus.AVAILABLE]}
							availableFilters={{
								available: true,
								delivered: false,
								aside: false,
								lost: false,
								inRecovery: false,
								underMaintenance: false,
								draft: false,
								left: false,
								recovered: false,
							}}
							maxQuota={
								financialForm.form.loanApplication?.approvedWeeklyPayment
							}
						/>
					</div>
				</SideModal>
			)}
			{openDetails && editData && (
				<EditOzoner
					open={openDetails}
					setOpen={setOpenDetails}
					ozoner={ozoner}
					form={editData?.form ? undefined : financialForm?.form}
					setResult={initializeContext}
					label={editData?.label}
					sections={editData?.sections}
					hasLoan={hasLoan}
				/>
			)}
			{openHistory && ozoner?._id && (
				<History
					entityId={[ozoner._id, financialForm.form._id]}
					open={openHistory}
					setOpen={setOpenHistory}
				/>
			)}
		</>
	) : null;
	//     {openModal && (
	//         <Modal
	//             handleClose={() => setOpenModal(false)}
	//             openConfirmation={openModal}
	//             title={modalData[modalOption]?.title}
	//             subtitle={modalData[modalOption]?.subtitle}
	//             icon={modalData[modalOption]?.icon}
	//             actions={[
	//                 {
	//                     label: "Volver",
	//                     color: "grey",
	//                     action: () => setOpenModal(false),
	//                 },
	//                 {
	//                     label: "Aceptar",
	//                     color: "primary",
	//                     action: modalData[modalOption]?.action,
	//                     loading: loadingModal,
	//                 },
	//             ]}
	//         />
	//     )}

	//     <TruoraDetails
	//         open={openTruora}
	//         setOpen={setOpenTruora}
	//         truoraData={ozoner?.truoraData}
	//         ozonerDocuments={ozoner?.documents}
	//     />
	//     <PalencaDetails
	//         open={openPalenca}
	//         setOpen={setOpenPalenca}
	//         palencaData={ozoner?.palencaData}
	//         getPalencaLink={getPalencaLink}
	//     />
};

export default DetailsOzoner;
