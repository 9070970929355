/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import { Typography } from 'design_system/src';
import moment from 'moment';

interface CounterProps {
	date: string;
	colorMap?: {
		maxValue: number;
		color: string;
	}[];
	scale?: string;
	weight?: string;
	className?: string;
}

const Counter: FC<CounterProps> = ({
	date,
	colorMap,
	scale = 'medium',
	weight = '400',
	className,
}) => {
	const [time, setTime] = useState('');
	const [color, setColor] = useState('neutral_1000');

	useEffect(() => {
		const timer = setInterval(() => {
			const now = moment();
			const realDate = moment(date);
			const minutes = now.diff(realDate, 'minutes');
			const hours = now.diff(realDate, 'hours');
			const days = now.diff(realDate, 'days');
			const diff = moment.utc(now.diff(realDate));
			if (hours < 1) {
				setTime(diff.format('mm:ss'));
			} else if (days < 1) {
				setTime(`>${hours} Horas`);
			} else {
				setTime(`>${days} Dias`);
			}
			let newColor = 'neutral_1000';
			colorMap
				?.sort((a, b) => a.maxValue - b.maxValue)
				.forEach((item) => {
					if (minutes < item.maxValue) {
						newColor = item.color;
					}
				});
			setColor(newColor);
		}, 1000);

		return () => clearInterval(timer); // Limpia el intervalo al desmontar
	}, []);
	return (
		<Typography
			scale={scale as any}
			weight={weight as any}
			textColor={color as any}
			className={className}
		>
			{time}
		</Typography>
	);
};

export default Counter;
